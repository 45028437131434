@import "../../variables.scss";

.language-desktop-menu-item {
    &__language {
        text-transform: uppercase;
        margin: 10px;
        color: $light-text-color;
        font-size: $extra-extra-large-font-size;
        font-weight: $medium-font-weight;
        transition: all 200ms;
        cursor: pointer;

        &:hover {
            color: $secondary-color;
        }
    }


    &__delimiter {
        margin: 10px 0;
        color: $light-text-color;
        font-size: $extra-extra-large-font-size;
        font-weight: $medium-font-weight;
    }
}