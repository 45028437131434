$white: #FAFAFA;
$black: #202020;
$green: #7C9678;
$green2: #6E8B69;
$orange: #FF481C;

$light-text-color: $white;
$dark-text-color: $black;
$main-color: $green;
$secondary-color: $orange;
$medium-font-weight: 500;
$regular-font-weight: 400;
$small-font-size: 9.5pt;
$medium-font-size: 12pt;
$large-font-size: 14pt;
$extra-large-font-size: 19pt;
$extra-extra-large-font-size: 20pt;
$huge-font-size: 23.5pt;

$web-screen-threshold-width: 1000px;
