@import "../../variables.scss";

.footer-desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 40px;
    font-size: $small-font-size;
    background-color: $white;

    ¨ &__cookies {
        margin-right: 20px;
    }
}

.footer-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    font-size: $small-font-size;
    background-color: $white;

    &__cookies {
        margin-right: 20px;
    }
}