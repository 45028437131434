@import "../../variables.scss";

.header {
    display: flex;
    justify-content: space-between;
    margin: 16px 50px;
    align-items: center;
    text-transform: uppercase;

    &__title {
        font-size: $extra-extra-large-font-size;
        font-weight: $medium-font-weight;
        color: $secondary-color;
    }
}