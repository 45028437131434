@import "../../variables.scss";

.desktop-price-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    color: $light-text-color;
    background: url("/images/tree.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 65%;

    &__content_wrapper {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: $large-font-size;
    }

    &__content {
        display: flex;
        line-height: 1.6;
        white-space: nowrap;
    }

    &__title_cell {
        display: flex;
    }

    &__price_unit_cell {
        padding-left: 8px;
    }

    &__text_column {
        flex-direction: column;
        width: 450px;
    }

    &__unit_column {
        width: 120px;
    }

    &__info {
        margin-top: 100px;
    }

    &__price_column {
        text-align: right;
    }

    &__currency_column {
        margin-left: 8px;
    }

    table,
    tr,
    td {
        white-space: nowrap;
    }
}

.mobile-price-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    color: $light-text-color;
    background: url("/images/tree.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 65%;

    &__list {
        display: flex;
        flex-direction: column;
        margin: 40px 50px;
    }

    &__info {
        margin: 30px;
        margin-top: 60px;
    }
}