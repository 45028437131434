@import "../../variables.scss";

.mobile-menu-item {
    text-transform: uppercase;
    margin: 10px;
    color: $light-text-color;
    font-size: $extra-extra-large-font-size;
    font-weight: $medium-font-weight;
    transition: all 200ms;

    &:hover {
        color: $secondary-color
    }
}