@import "../../variables.scss";

.profile {
    display: flex;
    flex: 1;
    background-color: white;
}

.desktop-profile {
    display: flex;
    flex: 1;
    flex-direction: column;

    &__content {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        margin: 50px 0;
    }

    &__right_panel {
        display: flex;
        justify-content: center;
    }

    &__right_panel_content {
        font-size: $medium-font-size;
        color: $green2;
        max-width: 800px;
        padding-right: 160px;
    }

    &__image {
        height: 300px;
        margin: 0 30px 0 150px;
    }

    &__reference {
        padding: 15px 0;
    }

    &__second-line {
        margin-top: 10px;
        margin-left: 20px;
    }
}

.mobile-profile {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: $white;
    color: $green2;

    &__content {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
    }

    &__text {
        display: flex;
        flex-direction: column;
        padding: 30px 45px;
        font-size: $medium-font-size;

        p {
            padding: 15px 0;
        }

        h3 {
            margin-bottom: 15px;
        }
    }

    &__image {
        margin: 0 auto;
        width: calc(100% - 90px);
    }

    &__reference {
        margin: 15px 0;
    }

    &__second-line {
        margin-top: 8px;
        margin-left: 30px;
    }
}