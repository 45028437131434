@import "../../variables.scss";

.desktop-menu {
    display: flex;
    position: sticky;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 100px;

    &__item {
        padding-top: 15px;
        display: flex;
        list-style-type: none;
    }
}

.mobile-menu {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0;
    padding: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    &__header {
        display: flex;
        left: 0;
        top: 0;
        right: 0;
        height: 56px;
        position: fixed;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        background-color: $white;
    }

    &__left-content {
        text-transform: uppercase;
        color: $orange;
        margin-left: 5px;
        font-size: $extra-extra-large-font-size;
        font-weight: $medium-font-weight;
    }

    &__item {
        display: flex;
        list-style-type: none;
        margin: 10px;
    }

    &__button {
        color: $orange;
        margin-right: 5px;
        cursor: pointer;
    }

    &__open {
        font-size: 20pt;
    }

    &__close {
        font-size: 40pt;
        line-height: 30pt;
        vertical-align: middle;
    }

    &__content {
        display: flex;
        background-color: $green;
        flex: 1;
        padding: 100px 50px;
        flex-direction: column;
    }

    &__children {
        margin-top: 56px;
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    &__toggler {
        position: absolute;
        cursor: pointer;
        width: 30px;
        height: 30px;
        opacity: 0;
        z-index: 2;
    }

    &__toggler:checked+&__hamburger>div {
        transform: rotate(135deg);

        &:before,
        &:after {
            top: 0;
            transform: rotate(90deg);
        }
    }

    &__hamburger {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        &>div {
            position: relative;
            background: $orange;
            width: 100%;
            height: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .4s ease;

            &:before,
            &:after {
                content: "";
                position: absolute;
                top: -10px;
                width: 100%;
                height: 2px;
                background: inherit;
                transition: all .4s ease;
            }

            &:after {
                top: 10px;
            }
        }
    }
}