@import "./variables.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* Remove tap highlight on mobile devices */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

body {
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: $regular-font-weight;
    color: $dark-text-color;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: $medium-font-size;
}

a {
    color: inherit;
    text-decoration: none;
}

h1 {
    margin: 0;
}