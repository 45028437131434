@import "../../variables.scss";

.desktop-homepage {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: $light-text-color;
    background: url("/images/tree.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 65%;

    &__title {
        text-transform: uppercase;
        font-weight: $medium-font-weight;
        font-size: $huge-font-size;

        &_language_english {
            font-size: 188.5%;
        }

        &_language_german {
            font-size: 195%;
        }

        &_language_czech {
            font-size: 270%;
        }
    }

    &__name {
        font-size: $huge-font-size;
        margin: 10px;
    }

    &__content {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 200px;
    }

    &__logo {
        width: 60px;
        height: 60px;
        margin: 20px;
        background: url("/images/logo.png");
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.mobile-homepage {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: $light-text-color;
    background: url("/images/tree.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 65%;
    font-size: $huge-font-size;

    &__title {
        text-transform: uppercase;
        font-weight: $medium-font-weight;
        font-size: 90%;

        &_language_english {
            font-size: 86%;
        }

        &_language_german {
            font-size: 89.5%;
        }

        &_language_czech {
            font-size: 123.5%;
        }
    }

    &__name {
        font-size: 90%;
        margin: 5px;
    }

    &__content {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 200px;
    }

    &__logo {
        width: 60px;
        height: 60px;
        margin: 20px;
        background: url("/images/logo.png");
        background-size: cover;
        background-repeat: no-repeat;
    }
}