.mobile-logo {
    display: block;
    width: 30px;
    height: 30px;
    background: url("/images/logo.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.desktop-logo {
    display: block;
    width: 50px;
    height: 50px;
    background: url("/images/logo.png");
    background-size: cover;
    background-repeat: no-repeat;
}