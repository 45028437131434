@import "../../variables.scss";

.services-desktop {
    display: flex;
    flex: 1;
    flex-direction: column;
    color: $light-text-color;
    background: url("/images/tree.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 65%;
}

.services-mobile {
    display: flex;
    flex: 1;
    flex-direction: column;
    color: $light-text-color;
    background: url("/images/tree.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 30%;
}

.services__content_wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.services__content {
    max-width: 800px;
    margin: 30px 45px;
}

.services__title {
    font-size: $extra-large-font-size;
}

.services__subtitle {
    font-size: $medium-font-size;
    margin-bottom: 30px;
}