@import "../../variables.scss";
.contact-desktop {
    display: flex;
    flex: 1;
    flex-direction: column;
    color: $light-text-color;
    background: url("/images/tree.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 65%;
     ::-moz-selection {
        color: $orange;
        background: $white;
    }
     ::selection {
        color: $orange;
        background: $white;
    }
    &__content-wrapper {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding-bottom: 50px;
    }
    &__content {
        display: flex;
        flex-direction: column;
        line-height: 1.6;
        font-size: $extra-large-font-size;
    }
    &__phone-link,
    &__mail-link {
        color: $light-text-color;
        transition: all 200ms;
    }
    &__phone-link:hover,
    &__mail-link:hover {
        color: $secondary-color
    }
    &__name {
        font-weight: $medium-font-weight;
        color: $secondary-color;
    }
    &__name,
    &__ico,
    &__dic,
    &__address {
        -moz-user-select: text;
        -khtml-user-select: text;
        -webkit-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }
}

.contact-mobile {
    display: flex;
    flex: 1;
    background: $white;
    flex-direction: column;
    font-size: 110%;
     ::-moz-selection {
        color: $white;
        background: $orange;
    }
     ::selection {
        color: $white;
        background: $orange;
    }
    &__name {
        color: $orange;
    }
    &__content-wrapper {
        display: flex;
        justify-content: center;
        flex: 1;
    }
    &__content {
        display: flex;
        width: 70%;
        flex-direction: column;
        margin: 60px;
    }
    &__phone-link,
    &__mail-link {
        transition: all 200ms;
    }
    &__phone-link:hover,
    &__mail-link:hover {
        color: $secondary-color
    }
    &__name,
    &__ico,
    &__dic,
    &__address {
        -moz-user-select: text;
        -khtml-user-select: text;
        -webkit-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }
}